import { type HandleError, useForm } from '@autone/ui';
import { createContext, useContext } from 'react';

type SubmissionErrorContextProps = {
  error: Record<string, unknown>;
  handleError: HandleError;
  isError: boolean;
};

const SubmissionErrorContext =
  createContext<SubmissionErrorContextProps | null>(null);

const SubmissionErrorContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { error, handleError, isError } = useForm();

  return (
    <SubmissionErrorContext.Provider
      value={{
        error,
        handleError,
        isError,
      }}
    >
      {children}
    </SubmissionErrorContext.Provider>
  );
};

export default SubmissionErrorContextProvider;

export const useSubmissionErrorContext = () => {
  const context = useContext(SubmissionErrorContext);
  if (!context) {
    throw new Error(
      'useSubmissionErrorContext must be used within a SubmissionErrorContextProvider',
    );
  }
  return context;
};
