import { type CommonTranslationFunction } from '@autone/translations';
import {
  type GeneralFiltersStorageKey,
  REPLENISHMENT_ADMIN,
  REPLENISHMENT_READONLY,
  REPLENISHMENT_STANDARD,
} from '@autone/utils';

import { type ReplenishmentTranslationFunction } from '../types/translations';

export const APP_NAME = 'replenishment';

export const getScopeDictionary = (
  t: ReplenishmentTranslationFunction,
  common: CommonTranslationFunction,
) => ({
  name: { name: t('scope.name') },
  distribution_points: { name: t('scope.distribution-points') },
  location_types: { name: common('scope.location-types') },
  regions: { name: common('scope.regions') },
  countries: { name: common('scope.countries') },
  locations: { name: common('scope.locations') },
  auto_submit_rules: { name: t('scope.auto-submit-rules') },
  departments: { name: common('scope.departments') },
  sub_departments: { name: common('scope.sub-departments') },
  collection_types: { name: common('scope.collection-types') },
  seasons: { name: common('scope.seasons') },
  products: { name: common('scope.products') },
  events: { name: common('scope.events') },
  brands: { name: common('scope.brands') },
  manufacturers: { name: common('scope.manufacturers') },
  coverage_options: { name: t('scope.coverage-options') },
  remove_markdown_products: { name: common('remove-markdown-products') },
});

export const INCL_ZERO_UNITS_SIDEPANEL_STORAGE_KEY =
  'include-zero-replen-units-sidepanel';

export const INCL_ZERO_UNITS_PRODUCT_TABLE_KEY =
  'include-zero-replen-units-product-table';

export const PRODUCT_TABLE_SESSION_STORAGE_KEY =
  'replenishment.productsTable.advancedFilters';

export const PRODUCT_LOCATIONS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.productLocationsTable.advancedFilters';

export const PRODUCT_SKUS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.productSkusTable.advancedFilters';

export const LOCATIONS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationsTable.advancedFilters';

export const LOCATION_DEPARTMENTS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationDepartmentsTable.advancedFilters';

export const LOCATION_PRODUCTS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationProductsTable.advancedFilters';

export const LOCATION_SKUS_TABLE_SESSION_STORAGE_KEY =
  'replenishment.locationSkusTable.advancedFilters';

export const COLUMN_LIMIT = 3;
export const DEFAULT_ALLOCATION_ERROR = 'OVER_ALLOCATION';
export const REPLENISHMENT_PERMISSIONS = [
  REPLENISHMENT_ADMIN,
  REPLENISHMENT_STANDARD,
  REPLENISHMENT_READONLY,
];
export const ENABLED_COLUMN_KEY = 'enabled_sort';

export const SIDE_PANEL_TABLE_HEIGHT = 800;
export const PUBLIC_URL = process.env.PUBLIC_URL || '';

/**
 * GENERAL FILTER KEYS
 */
type ReplenGeneralFilterStorageKeys<
  K extends GeneralFiltersStorageKey = GeneralFiltersStorageKey,
> = K extends `replenishment.${infer _}` ? K : never;

export const LOCATION_GENERAL_FILTER_KEY: ReplenGeneralFilterStorageKeys =
  'replenishment.locationsTable.generalFilters';
export const PRODUCT_GENERAL_FILTER_KEY: ReplenGeneralFilterStorageKeys =
  'replenishment.productsTable.generalFilters';
export const PIVOT_GENERAL_FILTER_KEY: ReplenGeneralFilterStorageKeys =
  'replenishment.pivotTable.generalFilters';
