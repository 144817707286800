import { AutoneTranslation, i18nAutone } from '@autone/translations';
import {
  AppShell,
  ErrorFallback,
  useAppSettings,
  usePriceTypeOptions,
} from '@autone/ui';
import {
  REPLENISHMENT_ADMIN,
  REPLENISHMENT_READONLY,
  REPLENISHMENT_STANDARD,
} from '@autone/utils';
import { ErrorBoundary } from 'react-error-boundary';

import { APP_NAME } from './constant';
import SubmissionErrorContextProvider from './features/submission-error/SubmissionErrorContext';
import useUser from './hooks/useUser';
import store from './redux/store';
import AppRoutes from './routes/app.routes';
import EditScheduleRoute from './routes/edit-schedule.route';
import ErrorRoute from './routes/error.route';
import LoadingRoute from './routes/loading.route';
import NewReplenishmentRoute from './routes/new-replenishment.route';

function App() {
  const routes = [
    EditScheduleRoute,
    NewReplenishmentRoute,
    ErrorRoute,
    LoadingRoute,
    AppRoutes,
  ];

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings(APP_NAME);

  // push the price type options and defaults into redux
  usePriceTypeOptions();

  // run the use user hook to retrieve user info
  useUser();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AutoneTranslation namespace={APP_NAME} i18n={i18nAutone}>
        <SubmissionErrorContextProvider>
          <AppShell
            reduxStore={store}
            routes={routes}
            permissionGroups={[
              REPLENISHMENT_ADMIN,
              REPLENISHMENT_STANDARD,
              REPLENISHMENT_READONLY,
            ]}
          />
        </SubmissionErrorContextProvider>
      </AutoneTranslation>
    </ErrorBoundary>
  );
}

export default App;
