import { coreApi, priceTypeReducer, userApi, userReducer } from '@autone/ui';
import { configureStore } from '@reduxjs/toolkit';

import { replenishmentApi } from './services/apis';

export const createStore = () =>
  configureStore({
    reducer: {
      [coreApi.reducerPath]: coreApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [replenishmentApi.reducerPath]: replenishmentApi.reducer,
      user: userReducer,
      priceType: priceTypeReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(coreApi.middleware)
        .concat(userApi.middleware)
        .concat(replenishmentApi.middleware),
  });

const store = createStore();

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
