import { AuthGuard, REPLEN_PATH_APP } from '@autone/ui';
import { lazy } from 'react';

import { PageLayout } from '../layout';

const EditScheduleRoute = {
  guard: AuthGuard,
  layout: PageLayout,
  path: REPLEN_PATH_APP.editSchedule,
  routes: [
    {
      exact: true,
      path: REPLEN_PATH_APP.editSchedule,
      component: lazy(() => import('../views/EditSchedule')),
    },
  ],
};

export default EditScheduleRoute;
